//export const QCFSSYSTEM_API_URL = "http://qcfssystem.local";
export const MAILER_URL = "https://mailer.qcfs.biz";
export const QCFS_VERSION = "1.0.0.3";
export const QCFS_CLUSTER = "azr2024";
export const QCFS_CLUSTER_NODE = "ecffdce0e803";

export default {
    QCFS_CLUSTER,
    QCFS_CLUSTER_NODE,
    QCFS_VERSION,
    MAILER_URL
}